import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const ExteriorDrainagePage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "exterior drainage maintenance services",
	  "alternateName": "exterior drainage repair services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "exterior drainage maintenance services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "exterior drainage maintenance services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "exterior drainage maintenance services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "exterior drainage repair services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Exterior Drainage Maintenance Services"
        description="Maintenance prevent floods, mold, and rot from developing by keeping water from seeping into your house."
        canonical="https://ecotechrestoration.ca/services/exterior-drainage-maintenance/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/drainage.jpg"
          quality={95}
          alt="Exterior Drainage Maintenance Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Exterior Drainage Maintenance
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>
          Do you have an Exterior Drainage Maintenance Emergency?
        </CallOut>
        <p>
          Functional drainage system maintenance is a key property upkeep
          element. This helps prevent floods, mold, and rot from developing by
          keeping water from seeping into your house. Routinely scheduled
          inspections and maintenance of systems by the professionals at Ecotech
          Restoration detect problems before they start and save you lots of
          money in the process. We’ll work within your budget to ensure your
          system is working without draining your bank!
        </p>
        <h2>Not every drainage problem is preventable</h2>
        <p>
          Not every drainage problem can be preventable. The weather in
          Vancouver and the tri-cities include major storms, lots of rain, and
          high wind. And other factors, like an irregular maintenance schedule,
          changing the grade of your land during a renovation, and the age of
          your drainage system also contribute to drainage problems. In cases
          like this, whether its rebuilding your drain tile, installing a
          footing drain or catch basin, upgrading your gutters and tie-ins,
          re-grading your yard, busting-up clogs, or applying a waterproof
          membrane to prevent spillovers, Ecotech Restoration always has
          covered!
        </p>
        <h2>Do you have a plumbing emergency?</h2>
        <p className="services__alertP">
          If a plumbing emergency comes up, our 24-7 bonded, certified,
          professional plumbers will rush right over and divert the water from
          your home before the situation worsens.
        </p>
        <SplashTwo title="Exterior Drainage Maintenance" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default ExteriorDrainagePage
